/**
 * @namespace components
 */

import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import ProLink from '../../shared/ProLink/ProLink';
import { miniGazelle } from '../../../helpers';

import './CtaBlock.scss';

/**
 * @class CtaBlock
 *
 * Call to action block module for adding multiple buttons
 *
 * @memberof components
 *
 * @author Damian Ryan <damian.ryan@sony.com>
 *
 * @property {object} data - Primary object to insert data props into component
 * @property {string=} data.title - Display title from Protein
 * @property {string=} data.content - Display content from Protein
 * @property {string} data.alignment - Text alignment, one of: left, center, right
 * @property {string} data.logicalName - Logical name of the module, used as the HTML node ID
 * @property {string} data.backgroundImage - Link to the background image
 * @property {string} data.backgroundColor - Background color in valid CSS hexadecimal, rgb or string value
 * @property {string=} data.textColor - Color of the general text in valid CSS hexadecimal, rgb or string value
 * @property {array=} data.buttons - Array of buttons in standard button format
 * @property {string} data.buttons.title - Display title of button
 * @property {string=} data.buttons.link - Link passed to HREF of button anchor
 * @property {string=} data.buttons.priority - Numerical value advising the order to which the button should appear
 * @property {boolean} data.buttons.focus - Boolean value instructing the button to be a focus button or standard button
 * @property {boolean=} data.buttons.useLabel - Boolean value instructing the component to treat the title as a label key
 * @property {boolean=} data.dark - Boolean value instructing the component to change button based on light or dark background
 * @property {boolean=} data.buttonReordering - Boolean value instructing the component to re-order the buttons based on priority
 *
 * @example
 * return (
 *   <CtaBlock data={data} />
 * )
 */

const CtaBlock = (props = {}) => {
    const { data = {} } = props;
    const {
        logicalName,
        title,
        content,
        buttons,
        buttonReordering,
        backgroundColor,
        backgroundImage,
        textColor,
        alignment,
        dark
    } = data;

    const inlineStyle = {
        backgroundColor: backgroundColor,
        backgroundImage: backgroundImage ? `url('${backgroundImage}')` : null,
        color: textColor
    };

    const headerStyle = {
        color: textColor
    };

    const classes = classNames({
        'col-md-12 text-left': alignment === 'left',
        'col-md-10 text-center': alignment === 'center',
        'col-md-12 text-right': alignment === 'right'
    });

    const buttonWrapperClasses = classNames({
        'col-md-12': true,
        'cta-buttons': true,
        'text-left': alignment === 'left',
        'text-center': alignment === 'center',
        'text-right': alignment === 'right'
    });

    const wrapperClasses = classNames({
        'container-fluid': true,
        module: true,
        'cta-block': true,
        'cta-block-dark': dark,
        'cta-block-light': !dark
    });

    const buttonMarkup = ({ buttons = [], matches }) => {
        return buttons && buttons.length > 0
            ? buttons
                  .sort((firstPriority, lastPriority) => firstPriority.priority - lastPriority.priority)
                  .map((button, i) => {
                      const { useLabel, link, title, focus } = button;
                      return buttonReordering ? (
                          <ProLink
                              key={i}
                              to={link}
                              className={classNames({
                                  btn: true,
                                  'btn-lg': true,
                                  'btn-default': dark && focus === false,
                                  'btn-blue': focus === false && !dark,
                                  'btn-yellow': focus === true
                              })}
                          >
                              {useLabel ? miniGazelle.label(`productIntroButton${title}`, true) : title}
                          </ProLink>
                      ) : (
                          <ProLink
                              key={i}
                              to={link}
                              className={classNames({
                                  btn: true,
                                  'btn-lg': true,
                                  'btn-blue': !matches && !dark && i < buttons.length - 1,
                                  'btn-default': i < buttons.length - 1,
                                  'btn-yellow': i >= buttons.length - 1,
                                  'export-pdf': button.title === 'pdfDownload'
                              })}
                          >
                              {useLabel ? miniGazelle.label(`productIntroButton${title}`, true) : title}
                          </ProLink>
                      );
                  })
            : null;
    };

    return (
        <div className={wrapperClasses} id={logicalName} style={inlineStyle}>
            <div className="container">
                <div className="row justify-content-md-center">
                    <div className={classes}>
                        {title && <h2 style={headerStyle}>{title}</h2>}
                        {content && <p className="inner-content" dangerouslySetInnerHTML={{ __html: content }} />}
                    </div>
                </div>
                <div className="row">
                    <div className={buttonWrapperClasses}>{buttonMarkup({ buttons })}</div>
                </div>
            </div>
        </div>
    );
};

CtaBlock.propTypes = {
    data: PropTypes.shape({
        title: PropTypes.string,
        content: PropTypes.string,
        backgroundImage: PropTypes.string,
        backgroundColor: PropTypes.string,
        textColor: PropTypes.string,
        logicalName: PropTypes.string.isRequired,
        alignment: PropTypes.oneOf(['left', 'center', 'right']).isRequired,
        buttons: PropTypes.arrayOf(
            PropTypes.shape({
                useLabel: PropTypes.bool,
                link: PropTypes.string.isRequired,
                title: PropTypes.string.isRequired,
                priority: PropTypes.string,
                focus: PropTypes.bool
            })
        ),
        dark: PropTypes.bool,
        buttonReordering: PropTypes.bool
    })
};

export default CtaBlock;
